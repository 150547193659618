<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-27 18:37:09
 * @ Description: Component displaying the public general profile services tab.
 -->

<template>
    <v-row>
        <v-col
            cols="12"
            style="position: relative;"
        >
            <!-- Add Service Button -->
            <v-btn
                style="position: absolute; right: 16px; top: -92px;"
                class="white--text no-uppercase rounded-lg os-13-sb"
                color="#FFA858"
                v-if="!showAddServiceBoolean"
                @click="showAddService(true)"
            >
                <span v-if="!$store.state.general.customBreakpoints.xxs">Add Service</span>
                <v-icon
                    class="ml-1"
                >
                    {{ mdiPlusCircleOutline }}
                </v-icon>
            </v-btn>
            <!-- Add Service Button -->

            <!-- Card View -->
            <v-row
                v-if="selectedView === 'card'"
            >
                <!-- Services -->
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                    v-for="(item, index) in services"
                    :key="index"
                >
                    <v-card
                        height="295px"
                        width="100%"
                        max-width="280px"
                        class="rounded-lg pa-3 mx-auto"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                style="position: relative;"
                            >
                                <v-btn
                                    icon
                                    class="mr-2 pr-4"
                                    aria-label="notifications"
                                    style="position: absolute;"
                                >
                                    <v-badge
                                        overlap
                                        :content="item.notifications.number"
                                        color="#FFA858"
                                    >
                                        <v-icon
                                            color="#2E567A"
                                            class="ml-4"
                                        >
                                            {{ mdiBell }}
                                        </v-icon>
                                    </v-badge>
                                </v-btn>
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            class="mr-2 pr-4"
                                            aria-label="notifications"
                                            style="position: absolute; right: 0;"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon
                                                color="#2E567A"
                                                class="ml-4"
                                            >
                                                {{ mdiDotsHorizontal }}
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for="(item, index) in menuItems"
                                            :key="index"
                                        >
                                            <v-list-item-title
                                                class="os-12-sb"
                                            >
                                                {{ item }}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                <!-- <v-btn
                                    icon
                                    class="mr-2 pr-4"
                                    aria-label="notifications"
                                    style="position: absolute; right: 0;"
                                >
                                    <v-icon
                                        color="#2E567A"
                                    >
                                        {{ mdiDotsHorizontal }}
                                    </v-icon>
                                </v-btn> -->
                                <v-img
                                    :src="item.image"
                                    height="64px"
                                    width="64px"
                                    contain
                                    class="mx-auto"
                                />
                            </v-col>
                            <v-col
                                class="os-16-sb text-center py-0"
                                cols="12"
                            >
                                {{ item.name }}
                            </v-col>
                            <v-col
                                class="os-12-r text-center"
                                cols="12"
                                style="color: #8F9BB3; height: 78px; max-height: 78px; overflow-y: auto;"
                            >
                                {{ item.description }}
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="8"
                            >
                                <div
                                    class="os-10-r"
                                    style="color: #8F9BB3;"
                                >
                                    Industry:
                                </div>
                                <div
                                    class="os-12-sb"
                                    style="color: #2E567A"
                                >
                                    {{ item.industry.name }}
                                </div>
                            </v-col>
                            <v-col
                                cols="4"
                                align-self="center"
                                class="text-right mt-n1 px-2"
                            >
                                <div
                                    class="os-12-sb"
                                    style="color: #069B34;"
                                >
                                    Active ({{ item.requests.active }})
                                </div>
                                <div
                                    class="os-12-sb"
                                    style="color: #FFA858;"
                                >
                                    Pending ({{ item.requests.pending }})
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="7"
                                class="os-12-b"
                                style="color: #2E567A;"
                            >
                                {{ item.rate.currency.symbol }}{{ item.rate.amount }} per {{ item.rate.interval.name }}
                            </v-col>
                            <v-col
                                cols="5"
                                class="text-right os-12-sb"
                                style="color: #2E567A;"
                            >
                                <span>{{ item.location.name }}</span>
                                <v-icon
                                    color="#2E567A"
                                >
                                    {{ mdiMapMarkerOutline }}
                                </v-icon>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <!-- Services -->
            </v-row>
            <!-- Card View -->

            <!-- List View -->
            <v-row
                v-if="selectedView === 'list'"
            >
                <!-- Search -->
                <v-col
                    cols="12"
                    class="pb-0 pt-10"
                >
                    <div
                        class="os-17-sb px-3"
                        style="background-color: white; height: 60px; display: flex; align-items: center;"
                    >
                        All Services
                        <v-spacer />
                        <div
                            style="width: 275px;"
                        >
                            <v-text-field
                                dense
                                hide-details
                                outlined
                                color="#2E567A"
                                placeholder="Search..."
                                class="os-13-r"
                                background-color="white"
                                style="width: 275px;"
                            >
                                <template v-slot:append>
                                    <v-icon
                                        color="#C5CEE0"
                                        class="ma-0"
                                    >
                                        {{ mdiMagnify }}
                                    </v-icon>
                                </template>
                            </v-text-field>
                        </div>
                    </div>
                </v-col>
                <!-- Search -->

                <!-- Services -->
                <v-col
                    cols="12"
                    class="pt-0"
                >
                    <v-data-table
                        :headers="serviceTableHeaders"
                        :items="services"
                        item-key="id"
                        hide-default-footer
                    >
                        <template v-slot:[`item.rate`]="{ item }">
                            {{ item.rate.currency.symbol }}{{ item.rate.amount }} per {{ item.rate.interval.name }}
                        </template>
                        <template v-slot:[`item.industry`]="{ item }">
                            {{ item.industry.name }}
                        </template>
                        <template v-slot:[`item.jobTitle`]="{ item }">
                            {{ item.jobTitle.name }}
                        </template>
                        <template v-slot:[`item.location`]="{ item }">
                            {{ item.location.name }}
                        </template>
                        <template v-slot:[`item.requests`]="{ item }">
                            <span
                                class="os-12-sb"
                                style="color: #069B34;"
                            >
                                Active ({{ item.requests.active }})
                            </span>
                            &nbsp;
                            <span
                                class="os-12-sb"
                                style="color: #FFA858;"
                            >
                                Pending ({{ item.requests.pending }})
                            </span>
                        </template>
                        <template v-slot:[`item.action`]>
                            <v-btn
                                icon
                            >
                                <v-icon
                                    color="#2E567A"
                                    style="opacity: 0.65;"
                                    size="20"
                                >
                                    {{ mdiArrowRightThinCircleOutline }}
                                </v-icon>
                            </v-btn>
                            <v-btn
                                icon
                            >
                                <v-icon
                                    color="#2E567A"
                                    style="opacity: 0.65;"
                                    size="20"
                                >
                                    {{ mdiPrinterOutline }}
                                </v-icon>
                            </v-btn>
                            <v-btn
                                icon
                            >
                                <v-icon
                                    color="#2E567A"
                                    style="opacity: 0.65;"
                                    size="20"
                                >
                                    {{ mdiTrashCanOutline }}
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
                <!-- Services -->
            </v-row>
            <!-- List View -->

            <!-- Add View -->
            <!-- Desktop -->
            <v-row
                v-if="showAddServiceBoolean && $vuetify.breakpoint.mdAndUp"
                style="position: absolute; z-index: 1; top: 0; background-color: #F9FBFD; width: 100%; min-height: 100%;"
                class="mt-n1"
            >
                <v-col
                    cols="9"
                >
                    <v-row>
                        <!-- Heading -->
                        <v-col
                            cols="12"
                            class="os-22-sb"
                        >
                            Add a Service
                        </v-col>
                        <v-col
                            cols="7"
                            class="py-0"
                        >
                            <v-divider />
                        </v-col>
                        <!-- Heading -->

                        <!-- Services Form -->
                        <v-col
                            cols="7"
                        >
                            <v-row>
                                <v-col
                                    cols="12"
                                    class="pb-0 os-15-sb"
                                >
                                    Service Name<span style="color: red;">*</span>
                                    <br />
                                    <v-text-field
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r"
                                        background-color="white"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="py-0 os-15-sb"
                                >
                                    Industry<span style="color: red;">*</span>
                                    <br />
                                    <v-select
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r"
                                        background-color="white"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="py-0 os-15-sb"
                                >
                                    Job Title<span style="color: red;">*</span>
                                    <br />
                                    <v-select
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r"
                                        background-color="white"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="py-0 os-15-sb"
                                >
                                    Service Description<span style="color: red;">*</span>
                                    <br />
                                    <general-tip-tap-text-editor-component
                                        class="pb-3"
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col
                            class="os-13-r text-center pt-6"
                            cols="2"
                        >
                            <v-img
                                src="/dashboard/general/DashboardGeneralUploadImage.png"
                                height="100px"
                                width="100px"
                                contain
                                class="mb-2 mx-auto"
                            />
                            <span style="color: rgb(143, 155, 179); cursor: pointer;">Service Image</span>
                        </v-col>

                        <!-- Location -->
                        <v-col
                            cols="12"
                            class="os-22-sb"
                        >
                            Service Location
                        </v-col>
                        <v-col
                            cols="7"
                            class="py-0"
                        >
                            <v-divider />
                        </v-col>
                        <v-col
                            cols="7"
                            class="os-15-sb pb-0"
                        >
                            Please provide location information for service provided<span style="color: red;">*</span>
                        </v-col>
                        <v-col
                            cols="7"
                            class="py-0"
                        >
                            <v-radio-group
                                v-model="selectedServiceLocation"
                            >
                                <v-radio
                                    v-for="(item, index) in serviceLocations"
                                    :key="index"
                                    :label="item.text"
                                    :value="item.id"
                                    color="#2E567A"
                                    class="radio-text"
                                    :ripple="false"
                                />
                            </v-radio-group>
                        </v-col>

                        <!-- Google Autocomplete -->
                        <v-col
                            cols="7"
                            class="py-0 os-15-sb"
                            v-if="selectedServiceLocation > 1"
                        >
                            Address<span style="color: red;">*</span>
                            <br />
                            <general-google-autocomplete-address-component
                                @googlePlaceSelected="googlePlaceSelected"
                                :placeholder="'Type the address to start a Google Maps lookup'"
                            />
                        </v-col>
                        <!-- Google Autocomplete -->

                        <!-- Map -->
                        <v-col
                            cols="7"
                            class="pt-0"
                            v-if="selectedServiceLocation > 1"
                        >
                            <general-map-component
                                :mapMarkers="mapMarkers"
                            />
                        </v-col>
                        <!-- Map -->

                        <v-col
                            cols="7"
                            v-if="selectedServiceLocation === 3 && addresses.length > 0"
                        >
                            <v-row
                                v-for="(item, index) in addresses"
                                :key="index"
                                justify="center"
                                class="mx-3"
                            >
                                <v-col
                                    class="flex-grow-1 flex-shrink-0 text-truncate pa-0"
                                    align-self="center"
                                >
                                    <v-icon
                                        color="#2E567A"
                                        size="20"
                                        class="mb-1"
                                    >
                                        {{ mdiMapMarkerOutline }}
                                    </v-icon>
                                    {{ item.name }}
                                    <span>, {{ item.address_line_1 }}</span>
                                    <span>, {{ item.city }}</span>
                                    <span>, {{ item.state }}</span>
                                    <span>, {{ item.country.name }}</span>
                                </v-col>
                                <v-col
                                    class="flex-grow-0 flex-shrink-1 pa-0"
                                >
                                    <v-btn
                                        icon
                                        @click="removeAddress(item)"
                                    >
                                        <v-icon
                                            color="black"
                                            size="20"
                                        >
                                            {{ mdiClose }}
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- Location -->

                        <v-col
                            cols="12"
                            class="os-22-sb"
                        >
                            Rate
                        </v-col>
                        <v-col
                            cols="7"
                            class="py-0"
                        >
                            <v-divider />
                        </v-col>
                        <v-col
                            cols="7"
                        >
                            <v-row>
                                <v-col
                                    cols="4"
                                    class="pb-0 os-15-sb"
                                >
                                    Currency<span style="color: red;">*</span>
                                    <br />
                                    <v-select
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r"
                                        background-color="white"
                                    />
                                </v-col>
                                <v-col
                                    cols="4"
                                    class="pb-0 os-15-sb"
                                >
                                    Rate<span style="color: red;">*</span>
                                    <br />
                                    <v-text-field
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r"
                                        background-color="white"
                                    />
                                </v-col>
                                <v-col
                                    cols="4"
                                    class="pb-0 os-15-sb"
                                >
                                    Unit / Per<span style="color: red;">*</span>
                                    <br />
                                    <v-select
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r"
                                        background-color="white"
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- Services Form -->

                        <!-- Add/Cancel Buttons -->
                        <v-col
                            align-self="end"
                            class="pb-7"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                            >
                                Add Service
                                <v-icon
                                    class="ml-1"
                                >
                                    {{ mdiPlusCircleOutline }}
                                </v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="no-uppercase rounded-lg os-13-sb"
                                color="#8F9BB3"
                                @click="showAddService(false)"
                            >
                                Cancel
                            </v-btn>
                        </v-col>
                        <!-- Add/Cancel Buttons -->
                    </v-row>
                </v-col>
            </v-row>
            <!-- Desktop -->

            <!-- Mobile -->
            <v-row
                v-if="showAddServiceBoolean && $vuetify.breakpoint.smAndDown"
                style="position: absolute; z-index: 1; top: 0; background-color: #F9FBFD; width: 100%; min-height: 100%;"
                class="mt-n1"
            >
                <v-col
                    cols="12"
                >
                    <v-row>
                        <!-- Heading -->
                        <v-col
                            cols="12"
                            class="os-22-sb"
                        >
                            Add a Service
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0"
                        >
                            <v-divider />
                        </v-col>
                        <!-- Heading -->

                        <!-- Services Form -->
                        <v-col
                            class="os-13-r text-center pt-6"
                            cols="12"
                        >
                            <v-img
                                src="/dashboard/general/DashboardGeneralUploadImage.png"
                                height="100px"
                                width="100px"
                                contain
                                class="mb-2 mx-auto"
                            />
                            <span style="color: rgb(143, 155, 179); cursor: pointer;">Service Image</span>
                        </v-col>
                        <v-col
                            cols="12"
                            class="pb-0 os-15-sb"
                            align-self="end"
                        >
                            Service Name<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            class="py-0 os-15-sb"
                        >
                            Industry<span style="color: red;">*</span>
                            <br />
                            <v-select
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            class="py-0 os-15-sb"
                        >
                            Job Title<span style="color: red;">*</span>
                            <br />
                            <v-select
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0 os-15-sb"
                        >
                            Service Description<span style="color: red;">*</span>
                            <br />
                            <general-tip-tap-text-editor-component
                                class="pb-3"
                            />
                        </v-col>

                        <!-- Location -->
                        <v-col
                            cols="12"
                            class="os-22-sb"
                        >
                            Service Location
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0"
                        >
                            <v-divider />
                        </v-col>
                        <v-col
                            cols="12"
                            class="os-15-sb pb-0"
                        >
                            Please provide location information for service provided<span style="color: red;">*</span>
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0 px-2"
                        >
                            <v-radio-group
                                v-model="selectedServiceLocation"
                            >
                                <v-radio
                                    v-for="(item, index) in serviceLocations"
                                    :key="index"
                                    :label="item.text"
                                    :value="item.id"
                                    color="#2E567A"
                                    class="radio-text"
                                    :ripple="false"
                                />
                            </v-radio-group>
                        </v-col>

                        <!-- Google Autocomplete -->
                        <v-col
                            cols="12"
                            class="py-0 os-15-sb"
                            v-if="selectedServiceLocation > 1"
                        >
                            Address<span style="color: red;">*</span>
                            <br />
                            <general-google-autocomplete-address-component
                                @googlePlaceSelected="googlePlaceSelected"
                                :placeholder="'Type the address to start a Google Maps lookup'"
                            />
                        </v-col>
                        <!-- Google Autocomplete -->

                        <!-- Map -->
                        <v-col
                            cols="12"
                            class="pt-0"
                            v-if="selectedServiceLocation > 1"
                        >
                            <general-map-component
                                :mapMarkers="mapMarkers"
                            />
                        </v-col>
                        <!-- Map -->

                        <v-col
                            cols="12"
                            v-if="selectedServiceLocation === 3 && addresses.length > 0"
                        >
                            <v-row
                                v-for="(item, index) in addresses"
                                :key="index"
                                justify="center"
                                class="mx-3"
                            >
                                <v-col
                                    class="flex-grow-1 flex-shrink-0 text-truncate pa-0"
                                    align-self="center"
                                >
                                    <v-icon
                                        color="#2E567A"
                                        size="20"
                                        class="mb-1"
                                    >
                                        {{ mdiMapMarkerOutline }}
                                    </v-icon>
                                    {{ item.name }}
                                    <span>, {{ item.address_line_1 }}</span>
                                    <span>, {{ item.city }}</span>
                                    <span>, {{ item.state }}</span>
                                    <span>, {{ item.country.name }}</span>
                                </v-col>
                                <v-col
                                    class="flex-grow-0 flex-shrink-1 pa-0"
                                >
                                    <v-btn
                                        icon
                                        @click="removeAddress(item)"
                                    >
                                        <v-icon
                                            color="black"
                                            size="20"
                                        >
                                            {{ mdiClose }}
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- Location -->

                        <v-col
                            cols="12"
                            class="os-22-sb"
                        >
                            Rate
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0"
                        >
                            <v-divider />
                        </v-col>
                        <v-col
                            cols="6"
                            class="pb-0 os-15-sb"
                        >
                            Currency<span style="color: red;">*</span>
                            <br />
                            <v-select
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                            />
                        </v-col>
                        <v-col
                            cols="6"
                            class="pb-0 os-15-sb"
                        >
                            Rate<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                            />
                        </v-col>
                        <v-col
                            cols="6"
                            class="os-15-sb"
                        >
                            Unit / Per<span style="color: red;">*</span>
                            <br />
                            <v-select
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                            />
                        </v-col>
                        <!-- Services Form -->

                        <!-- Add/Cancel Buttons -->
                        <v-col
                            cols="6"
                            :class="$store.state.general.customBreakpoints.xxs ? 'px-0' : 'px-2'"
                            align-self="center"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                            >
                                <span v-if="!$store.state.general.customBreakpoints.xxs">Add</span>
                                <v-icon
                                    class="ml-1"
                                >
                                    {{ mdiPlusCircleOutline }}
                                </v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="no-uppercase rounded-lg os-13-sb"
                                color="#8F9BB3"
                                @click="showAddService(false)"
                            >
                                Cancel
                            </v-btn>
                        </v-col>
                        <!-- Add/Cancel Buttons -->
                    </v-row>
                </v-col>
            </v-row>
            <!-- Mobile -->
            <!-- Add View -->
        </v-col>
    </v-row>
</template>
<script>
    import { mdiClose, mdiPlusCircleOutline, mdiBell, mdiDotsHorizontal, mdiMapMarkerOutline, mdiArrowRightThinCircleOutline, mdiPrinterOutline, mdiTrashCanOutline, mdiMagnify } from '@mdi/js'
    import GeneralTipTapTextEditorComponent from '@/components/general/tiptap/GeneralTipTapTextEditorComponent.vue'
    import GeneralMapComponent from '@/components/general/map/GeneralMapComponent'
    import GeneralGoogleAutocompleteAddressComponent from '@/components/general/google/GeneralGoogleAutocompleteAddressComponent.vue'

    export default {
        name: 'ProfilePublicServicesTabComponent.vue',

        components: {
            GeneralTipTapTextEditorComponent,
            GeneralMapComponent,
            GeneralGoogleAutocompleteAddressComponent
        },

        props: {
            selectedView: {
                type: String,
                required: true
            }
        },

        data () {
            return {
                mdiClose,
                mdiPlusCircleOutline,
                mdiBell,
                mdiDotsHorizontal,
                mdiMapMarkerOutline,
                mdiArrowRightThinCircleOutline,
                mdiPrinterOutline,
                mdiTrashCanOutline,
                mdiMagnify,
                showAddServiceBoolean: false,
                serviceTableHeaders: [
                    {
                        text: 'Service',
                        value: 'name'
                    },
                    {
                        text: 'Rate',
                        value: 'rate'
                    },
                    {
                        text: 'Industry',
                        value: 'industry'
                    },
                    {
                        text: 'Title',
                        value: 'jobTitle'
                    },
                    {
                        text: 'Location',
                        value: 'location'
                    },
                    {
                        text: 'Service Requests',
                        value: 'requests'
                    },
                    {
                        text: 'Action',
                        value: 'action'
                    }
                ],
                services: [
                    {
                        id: 1,
                        image: '/general/FixerIndividualIcon.png',
                        name: 'Digital Illustrator & Designer',
                        industry: {
                            id: 1,
                            name: 'Marketing & Advertising'
                        },
                        jobTitle: {
                            id: 1,
                            name: 'Illustrator'
                        },
                        description: 'Illustrator with 25 years experience. Portfolio available. I work hourly or project based. Available immediately.',
                        location: {
                            id: 1,
                            name: 'Remote'
                        },
                        rate: {
                            currency: {
                                id: 1,
                                symbol: '$',
                                name: 'US Dollar'
                            },
                            amount: 500,
                            interval: {
                                id: 1,
                                name: 'hour'
                            }
                        },
                        requests: {
                            active: 1,
                            pending: 2
                        },
                        notifications: {
                            number: 1
                        }
                    }
                ],
                serviceLocations: [
                    {
                        id: 1,
                        text: 'Virtual Service (Service provided remotely)'
                    },
                    {
                        id: 2,
                        text: 'Provided at a specific location (i.e. Address, Gym, Office)'
                    },
                    {
                        id: 3,
                        text: 'Multiple Locations (More than one Address)'
                    }
                ],
                selectedServiceLocation: 1,
                menuItems: [
                    'View',
                    'Print',
                    'Delete'
                ],
                mapMarkers: [],
                addresses: []
            }
        },

        methods: {
            showAddService (value) {
                this.showAddServiceBoolean = value
            },

            googlePlaceSelected (place) {
                if (this.selectedServiceLocation === 2) {
                    this.addresses = [ place ]
                    this.mapMarkers = [
                        {
                            lat: place.lat,
                            lng: place.lng
                        }
                    ]
                } else if (this.selectedServiceLocation === 3) {
                    const addressIndex = this.addresses.findIndex(addressToFind => addressToFind === place)
                    if (addressIndex < 0) this.addresses.push(place)

                    const mapMarkerIndex = this.mapMarkers.findIndex(mapMarkerToFind => mapMarkerToFind.lat === place.lat && mapMarkerToFind.lng === place.lng)
                    if (mapMarkerIndex < 0) this.mapMarkers.push({ lat: place.lat, lng: place.lng })
                }
            },

            removeAddress (item) {
                const addressIndex = this.addresses.findIndex(addressToFind => addressToFind === item)
                if (addressIndex > -1) this.addresses.splice(addressIndex, 1)

                const mapMarkerIndex = this.mapMarkers.findIndex(mapMarkerToFind => mapMarkerToFind.lat === item.lat && mapMarkerToFind.lng === item.lng)
                if (mapMarkerIndex > -1) this.mapMarkers.splice(mapMarkerIndex, 1)
            }
        }
    }
</script>
<style scoped>
    >>> .v-btn:not(.v-btn--round).v-size--default {
        min-width: 0;
    }

    >>> .v-list-item {
        min-height: 0;
        height: 30px;
    }

    >>> th {
        font-size: 15px !important;
        font-weight: 600 !important;
        color: #8F9BB3 !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
    }

    >>> td {
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
    }

    >>> thead {
        background-color: #EFF4FA !important;
    }

    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }

    .radio-text /deep/ label {
        color: black !important;
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
    }
</style>
